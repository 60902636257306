* {
    margin: 0;
    padding: 0;
}

.dropdown {
    display: block;
}

.sidebarUl{
    height: 65vh;
    overflow: auto;
    padding: 0;
    width: 100%;
}

.sidebarUl .sidebarIl{
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
}

.sidebarUl .sidebarIl:hover{
    cursor: pointer;
    background-color: #EAF2FF;
}

.sidebarUl #active{
    background-color: #EAF2FF;
    color: #3EB971;
}

.sidebarIl #icon{
    flex: 15%;
    display: grid;
    place-items: center;
}

.sidebarIl #title{
    flex: 90%;
    
}

.adminTop{
    margin-top: -150px;
    
}

.logoutDiv{
    height: auto;
    padding: 0;
    width: 100%;
}

.logoutDiv .logInnDiv {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #3EB971;
    padding-left: 10%;
}

.logInnDiv #icon {
    flex: 15%;
    display: grid;
    place-items: center;
}

.logInnDiv #out {
     flex: 90%;
}

.adminUser{
    height: auto;
    padding: 0;
    width: 100%;
}

.adminUser .adminUserPar {
    width: 100%;
    height: 50px;
    list-style-type: none;
    margin: 0;
    margin-left: 13%;
    margin-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}

.pborder{
    border-bottom: 2px solid #EBF2F7;
    margin: 10px 20px;
}

.admin1-bg{
    background-image:
    linear-gradient(to bottom, rgba(247, 182, 24, 0.52), rgba(62, 185, 113, 0.73)),
    url('../images/littlefarm.png');
    /* background-image: url(../images/littlefarm.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-in-form{
    width: 30rem;
    background: rgba(39, 45, 59, 0.52);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    /*
    height: 80%;
    */
    
}

.form-login{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sign-in-form > h3{
    font-size: 30px;
    color: #FFFFFF;
}

.sign-in-form  p{
    font-size: 20px;
    color: #FFFFFF;
    padding: 15px;
}

.label{
    margin-top: 90px;
}

.input,
.input::-webkit-input-placeholder {
    /* font-size: 30px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0px !important;
    background-color: transparent;
    color: #fff !important;
    line-height: 2.5 !important;
    outline-width: 0; */
}

.login-input{
    width: 100%;
    height: 50px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid #FFFFFF !important;
    color: #FFFFFF !important;
    background: transparent !important;
    padding: 0 !important;
    border-radius: 0 !important;
    /* font-size: 30px; */
}

.login-input:focus {
    outline-width: 0 !important;
    color: #0E0E0E;
}

.login-input:active {
    outline-width: 0 !important;
    background: transparent !important;
}


.login-input:visited {
    outline-width: 0 !important;
    background: transparent !important;
}

.login-input::placeholder{
    color: #FFFFFF !important;
}

.login-input:-ms-input-placeholder{
    color: #FFFFFF !important;
}

.admin1-sigin-btn{
    background: transparent linear-gradient(180deg, #3EB971 0%, #088A3E 100%) 0% 0% no-repeat padding-box !important;
    border: 0px solid #707070 !important;
    border-radius: 15px;
    opacity: 1;
    width: 100% !important;
}

.admin-bg{
    background-color: #EAF2FF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.main {
    width: 90%;
    max-width: 120rem;
    margin: auto;
    position: relative;
    /* padding-top: 10%; */
}

.admin-side{
    width: 20vw;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 10px 20px #494b4e66;
}

.admin1-sidebar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #46484a66;
    border-radius: 15px !important;
    overflow-y: auto !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    /*
    height: 80vh !important;
    */
}

.admin1-sidebar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.admin1-sidetext {
    padding: 1rem;
    /* margin-top: 150px; */
    text-align: left;
}

.admin1-sidetext>p {
    font-size: 16px;
    letter-spacing: 0px;
    color: #272D3B;
}

.admin1-sidetext > p > a {
    text-decoration: none !important;
    color: inherit;
}

.admin1-sidetext>h5 {
    font-weight: 600;
    letter-spacing: 0px;
    color: #272D3B;
}

.active-side-link{
    color: #3EB971 !important;
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    background: #EAF2FF;
    padding: 5px;
    border-radius: 10px;
}

.userManager{
    background: #EAF2FF;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 3%;
    margin-right: 3%;
}

.userManager-cont{
    margin-left: 20px;
    padding:  20px;
    height:  80vh;
    width: 70vw;
    
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #494b4e66;
    border-radius: 15px; 
    overflow: auto;
}

.site-info {
    box-shadow: 3px 3px 10px #00000029;
    padding-right: 10px;
}

.autoscroll {
    
    height:  66vh;
    overflow: auto !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.autoscroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.adminScroll {
    height:  80vh;
    /*
    overflow: auto !important;
    */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}


.adminScroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.hide-scroll{
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.overflow{

    overflow-y: auto !important;
}

td{
    text-align: left !important;
}

.display-admin{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.stat {
    background-color: #EAEAEA;
    margin-right: 10px;
    margin-top: 20px !important;
    border-radius: 7px;
    box-shadow: 0px 3px 6px #00000029;
}

.stat:hover,
.stat:focus {
    background: #3EB971;
    animation: alternate;
}

.dash-product-card{
    text-align: center;
    background-color: #EAEAEA;
    cursor: pointer;
}

.input-icons i {
    position: absolute;
}

.input-icons {
    margin-bottom: 10px;
}

.icon {
    padding: 5px;
    color: #D0DBE5;
    min-width: 50px;
    text-align: center;
}

.input-field {
    width: 100%;
    padding: 10px;
    text-align: center;
    background: #EAF2FF !important;
    border-radius: 21px;
    font: normal normal normal 12px;
    letter-spacing: 0px;
    color: #D0DBE5;
    padding-left: 40px;
    border: none;
}

.admin-title{
    text-align: left;
    font-size: 23px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0E0E0E;
}

.admin-del-btn{
    background: #EC2A82;
    border-radius: 3px;
    border: none !important;
    height:25px !important;
    font-weight: 700;
    padding-top: 2px !important;
}

.sort-by {
    width: 100px;
    border: 1px solid #707070;
    /* height: 40px; */
    padding: 10px 0;
    border-radius: 3px;
}

.add-btn{
    background: #3EB971;
    color: #FFFFFF;
    padding: 8px 10px;
    border-radius: 3px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    width: 122px;
    text-align: center;
    cursor: pointer;
}

.bulk-action-btn{
    background: #3EB971 !important;
    border-radius: 3px;
    border: none !important;
    height:25px !important;
    font-weight: 700;
    padding-top: 2px !important;
}

.admin-show{
    font-size: 12px;
}

.col-card{
    padding-left: 0px !important;
}

.dash-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px !important;
}

.admin-card-img{
    border-radius: 10px !important;
    height: 9rem;
    opacity: 0.57;
}

.admin-card-title{
    font-size: 25px !important;
    font-weight: 600 !important;
}

.admin-card-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.admin-card-m{
    color: #0E0E0E;
    font-weight: 500;
    font-size: 22px;
}

.admindash-card-u{
    width: 2.3rem !important;
    height: 2.3rem !important;
    border-radius: 50%;
    border: 2px solid #ffffff;
}

.admin-header-icon{
    color: #D0DBE5;
    background: #fff;
    border-radius: 50%;
    width: 15px !important;
    height: 15px !important;
    padding: 10px !important;
    margin: 0px 8px;
}

.category-card{
    box-shadow: 0px 3px 6px #00000029;
    /* border: 1px solid #707070; */
    margin: 10px;
    color: #272D3B;
}

.category-card-btn,
.category-card-btn:hover,
.category-card-btn:focus,
.category-card-btn:active {
    background: #F7B618;
    border-radius: 5px;
    border: none !important;
    /* height: 20px;
    padding-top: 2px !important; */
    color: #272D3B;
    font-size: 12px;
    font-weight: 700;
}

.category-card-title{
    font-size: 12px;
    padding-bottom: 10px;
    font-weight: 700;
}

.site-set-side{
    border: 1px solid #D3D3D3;
    font-size: 12px;
    /* padding-bottom: 22em; */
    height: 100%;
    
}

.site-set-side > p {
    padding: 10px;
    font-weight: 600;
}

.site-set-side > p > a{
    color: inherit;
    text-decoration: none;
}

.site-set-m-title {
    padding: 10px;
    font-weight: 600;
    color: #3EB971;
}

.site-set-btn,
.site-set-btn:hover,
.site-set-btn:active,
.site-set-btn:focus
.site-set-btn::after{
    font-size: 12px;
    font-weight: 600;
    width: 70px;
    margin: 10px;
    border: none !important;
}

.site-set-btn:first-child{
    background: #3EB971;
}

.site-set-btn:nth-child(2){
    background: #F7B618;
}

.site-set-m-slide{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
}

.site-set-m-slide > span{
    font-size: 13px;
}

.displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainslide-btn {
    border-radius: 5px;
    border: none !important;
    height: 25px !important;
    font-size: 13px;
    font-weight: 600;
    padding-top: 2px !important;
    width: 120px !important;
}

.language{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 2rem;
}

.gifHide {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 2px;
}

.gradient{
    background: transparent linear-gradient(180deg, #F7B618 0%, #3EB971 100%) 0% 0% no-repeat padding-box;
}

.tpro{
    width: 120px;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 120px;
}

.Mpro{
    width: 20vw;
    height: 20rem;
    border: 1px solid #707070;
    border-radius: 10px;
}

.product-details-btn {
    font-size: 12px;
    font-weight: 600;
    margin: 10px;
    border: none;
    border-radius: 10px;
}

.product-details-btn-second {
    font-size: 12px;
    font-weight: 600;
    margin: 10px;
    border: none;
    border-radius: 10px;
    border: none;
    background: #B93E3E !important;
    color: #FFFFFF;
}

.product-details-btn:first-child{
    border: 1px solid #851D1D;
    background: none !important;
    color: #B93E3E;
}

.product-details-btn:first-child:hover{
    border: 1px solid #851D1D;
    background: inherit !important;
    color: #B93E3E;
}

.product-details-btn:nth-child(2){
    border: none;
    background: #B93E3E !important;
    color: #FFFFFF;
}

.pie-frame{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 19px;
    margin-bottom: 40px;
    margin-left: 40px;
}

.graph-line{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 19px;
}

.siteview-chat{
    margin-bottom: 20px;
}

.pructVRate{
    display: flex;
    align-items: center;
}

.cat-details-btn,
.cat-details-btn:hover,
.cat-details-btn:active,
.cat-details-btn:focus,
.site-set-btn::after {
    font-size: 12px;
    font-weight: bold;
    width: 7rem;
    margin: 10px;
    border: none !important;
    border-radius: 4px;
}

.reset-submit-btn{
    text-align: right;
    margin-top: 15%;
}

.cat-details-btn:first-child {
    background: #FF0000;
}

/* .cat-details-btn:nth-child(2), */
.cat-details-btn:nth-child(3)
{
   background-color: #3EB971;
}

.cat-edit-btn,
.cat-edit-btn:hover,
.cat-edit-btn:active,
.cat-edit-btn:focus,
.cat-edit-btn::after{
    font-weight: 500;
    width: 136px;
    border-radius: 3px !important;
}

.cat-edit-btn:first-child{
    background: none;
    border: 1px solid #707070;
    color: #272D3B;
    margin-right: 10px;
}

.cat-edit-btn:nth-child(2){
    background: #3EB971;
    border: 1px solid #3EB971;
}

.page-btn,
.page-btn:focus,
.page-btn:active,
.page-btn:hover,
.page-btn::after{
    width: 102px;
    border: none !important;
}

.page-btn:first-child{
    margin-right: 10px;
}

.page-btn:nth-child(2){
    margin-left: 10px;
    background: #FF0000 !important;
}

.tes-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin: 3px;
}

.newUserCard{
    margin-bottom: 1rem;
}

.newUserCard-header {
    background: #3EB971 !important;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
}

.newUserCard-img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    border: 2px solid #ffffff;
}

.newUserCard-body{
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.displayFlexNew {
    display: flex;
    justify-content: left;
    align-items: center;
}

.borderless td,
.borderless th {
    border: none;
}

.select-all{
    width: 120px;
    height: 37px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 3px;
    opacity: 1;
    text-align: center;
    padding-top: 5px;
}

.add-pt-btn,
.add-pt-btn:focus,
.add-pt-btn:hover,
.add-pt-btn:active {
    background: #FFFFFF !important;
    color: #272D3B !important;
    border: 1px solid #B9B9B9 !important;
    border-bottom-left-radius: 0% !important;
    border-top-left-radius: 0% !important;
    height: 50px;
}

.top-banner img {
    width: 100%;
    height: 150px;
}

.add-slide-btn-div {
    text-align: right;
    margin: 10px 0;
}

.add-slide-btn {
    padding: 10px 25px;
    border: 1px solid #D3D3D3;
    border-radius: 3px;
}

.change-image-btn {
    background: none;
    margin-right: 10px;
}

.remove-image-btn {
    background-color: #D3D3D3;
}

.add-slide-top-btn-div {
    text-align: right;
    margin-bottom: 10px;
}

.add-slide-top-btn {
    padding: 5px 10px;
    width: 100px;
    color: #FFFFFF;
}

.slide-discard-btn {
    background: #FF0000;
    border: 1px solid #FF0000;
    margin: 10px;
}

.slide-save-btn {
    background: #3EB971;
    border: 1px solid #3EB971;
}

/* Styling the color input */
input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 50px;
	height: 50px;
    background: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}
input[type="color"]::-webkit-color-swatch {
	border: none;
    border-radius: 50px;
}

/* End Styling the color input */

.color-div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.add-slide-lower {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.add-slide-lower-input {
    height: 50px;
    border: 1px solid #D9D9D9;
    width: 100%;
}

/* -------------------------------------Styles added by Ivan ------------------*/

.custom_validation{

    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

.addCatBtn{
    background: #3EB971 !important;
    border: 1px solid #3EB971 !important;
    height: 40px !important;
    width: 135px;
}

.viewDetailsBtn {
    font-weight: 600 !important;
    border: 1px solid #272D3B !important;
    background: #FFFFFF !important;
    color: #272D3B !important;
    border-radius: 3px !important;
    cursor: pointer;
}

.viewDetailsBtn:hover,
.viewDetailsBtn:focus {
    border: 1px solid #F7B618 !important;
    color: #F7B618 !important;
}

.my-added-types{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


/* **********************************************************
*                          Styles added by abdqadir
**************************************************************/

.content-container::-webkit-scrollbar {
    display: none ; /* Safari and Chrome */
} 

.content-container{
    height: '70vh'
}

.profile-details .personal-details{
    display: flex;

}

.profile-details .personal-details img{
 
top: 311px;
left: 639px;
width: 300px;
height: 379px;
border: 1px solid #707070;
border-radius: 5px;
opacity: 1;
}

.profile-details ul li{
list-style: none;
}

.profile-details .personal-details ul.details li .email{
    color: #FF2300;
    text-decoration: underline;
    letter-spacing: 2px;
}


.personal-details,
.bio-details,
.notification-details,
.reset-password-details,
.permission-details,
.account-access-details {
    padding-top: 10px;
}

.details-head h5{
    text-transform: uppercase;
    font-size:20px;
    font-weight: bold;
}
.profile-details .bio-details p{
border: 1px solid #aaa;
height: 90px;
border-radius: 3px;
}

.profile-details .notification-details span{
    font-weight: bold;
    
}

/* .profile-details .notification-details p,
.profile-details .reset-password-details p,
.profile-details .permission-details p,
.profile-details .account-access-details p
{
    font-size: 16px ;
} */

.profile-details .reset-password-details p .email{
    text-decoration: underline;
    color: #3EB971;
}
.profile-details .permission-details span{
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.profile-details .account-access-details .actions{
    margin-bottom: 30px;
}

.profile-details .account-access-details h6{
    font-size: 20px;
    font-weight: bold;
    
}


.userManager-cont .follow{

    display: inline-flex; 
    font-size: small;    
}
.userManager-cont .follow .item{
    padding: 0px 15px 0px 35px;
}

.userManager-cont .follow .item span{
    display: table;
    margin: 0 auto;
}



.user-details{
    display: inline-flex;
    width: 100%;
}

.user-details img{
    position:relative;
    width:300px;
    height:350px; 
    border-radius: 5px;  
    /* overflow:visible; */
        

}







.user-details .user-portifolio ul li{
    border-bottom: 1px solid #eee;
    padding: 6px 0;
    list-style: none;

}

.user-details .user-portifolio ul li .category .description{
float: right;
}

.user-details .user-portifolio .user-about{
    margin-top: 15px;    

}

.user-details .user-portifolio .user-about h5{
    color: #3EB971;
    text-transform: uppercase;
    font-size: large;
    padding-bottom: 15px;
}


.user-details .user-btn {
    max-width: 300px;
    margin-top: 15px;
    position: relative;

}

.user-details .user-btn button.delete-user {

border: 1px solid #3EB971;
background: #3EB971 0% 0% no-repeat padding-box;
border-radius: 5px;
padding:5px 30px 5px 30px;
text-align: left;
letter-spacing: 0px;
color: #fff;

}

.user-details .user-btn button.verify-user {
    border: 1px solid #3EB971;
    background: #fff 0% 0% no-repeat padding-box;
    color: #3EB971;
    padding:5px 30px 5px 30px;
    border-radius: 5px;
    float: right;
}

.user-details .user-btn button.view-products {
    border: 1px solid #F7B618;
    background: #F7B618 0% 0% no-repeat padding-box;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    padding: 5px 0px;
}
    /* height: 40px !important;
    width: 135px;
} */

.reset-btn{
    background: #FFFFFF !important;
    border: 1px solid #707070 !important;
    color: #272D3B !important;
    height: 40px !important;
    width: 130px;
}


/* Image removal class added by Ivan*/
.artist-collection-photo {
    float: left;
    margin: 10px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    position: relative;
  }
  .close{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    font-size: 20px;
    color: white;
    margin-top: 5px;
    margin-right: 10px;
  }
  .img-thumbnail {
    opacity: 1;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
  }
  
  .img-thumbnail:hover {
    opacity: 0.5;
  }
  

  /* sidebar test */

  .sidebar{
    width: 260px;
    flex-shrink: 0;
    background-color: rgba(22, 22, 22, 1);
    height: 100%;
    overflow: auto;
  }
  
  
  .sidebar-item{
    padding: .75em 1em;
    display: block;
    transition: background-color .15s;
    border-radius: 5px;
  }
  .sidebar-item:hover{
    background-color: rgba(255, 255, 255, .1);
  }
  
  .sidebar-title{
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
  }
  .sidebar-title span i{
    display: inline-block;
    width: 1.5em;
  }
  .sidebar-title .toggle-btn{
    cursor: pointer;
    transition: transform .3s;
  }
  .sidebar-item.open > .sidebar-title .toggle-btn{
    transform: rotate(180deg);
  }
  .sidebar-content{
    padding-top: .25em;
    height: 0;
    overflow: hidden;
  }
  .sidebar-item.open > .sidebar-content{
    height: auto;
  }
  
  .sidebar-item.plain{
    color: #fff;
    text-decoration: none;
  }
  .sidebar-item.plain:hover{
    text-decoration: underline;
  }
  .sidebar-item.plain i{
    display: inline-block;
    width: 1.7em;
  }