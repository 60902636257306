.modal-dialog{
    max-width: 95% !important;
}

.border-right {
    border-right: 1px solid #dee2e6;
    background: #f1f1f1;
  }
  .border-bottom {
    border-bottom: 1px solid #dee2e6;
  }
  .padding{
    padding: 15px;
  }